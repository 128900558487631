import React, { Component } from "react"
import { navigate } from "gatsby-link"
import "../css/form.css"
import "../css/link.css"
import { Button, Parent, Input } from "../css/posed"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ContactForm extends Component {
  state = { email: "", message: "", open: false }
  componentDidMount() {
    // setTimeout(() => this.animate(), 2000)
  }
  animate = () => {
    let open = !this.state.open
    this.setState({ open })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }
  render() {
    let {open} = this.state
    return <form name="contact" method="POST" data-netlify="true" action="/Thanks/" className="form" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />

      <div className="link" onClick={this.animate}>
        Contact Form
      </div>
      <Parent pose={open ? "enter" : "exit"}>
      <Input>
        <label>
          <span className="strong">Email 📧</span>
          <input placeholder="jack.bauer@protonmail.com" type="email" name="email" className="block br-5 input-border full-width" onChange={this.handleChange} />
        </label>
      </Input>
      <Input>
        <label>
          <span className="strong">Message ✒️</span>
          <textarea placeholder="Antoine you're so cool, etc." name="message" className="block br-5 input-border full-width" onChange={this.handleChange} />
        </label>
      </Input>
      <Button>
        <button type="submit" className="link">
          Send
        </button>
      </Button>
      </Parent>
    </form>
  }
}
