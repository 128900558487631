import React, { Component } from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import Link from "../components/Link"

import "../css/link.css"

import styled from "styled-components"
import fly from "../images/fly.png"
import { FaHome, FaTelegramPlane } from "react-icons/fa"

import ContactForm from "../components/ContactForm"

const Home = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  & a {
    background-color: #fff;
    box-shadow: 0 0 0 white;
  }
`
const footerHeight = "60px"
const Bottom = styled.div`
  right: 0;
  left: 0;
  bottom: ${footerHeight};
  /* position: absolute; */
  text-align: center;
`

// const Tiny = styled.div`
//   font-size: 70%;
//   margin-top: 10px;
//   p {
//     margin: 0;
//   }
// `

class Contact extends Component {
  render() {
    return (
      <Layout>
        <div className="text-center">
          <Helmet
            title={`Contact Antoine`}
            meta={[
              { itemprop: "name", content: `Contact Antoine` },
              { itemprop: "description", content: "Reach out" },
              { itemprop: "image", content: fly },
              { name: "keywords", content: "contact, message, email" },
              { property: "og:title", content: `Contact Antoine` },
              { property: "og:image:url", content: fly },
              { property: "og:image:width", content: "750" },
              { property: "og:image:height", content: "750" },
              {
                property: "og:url",
                content: "https://www.magnyficent.com/content",
              },
              { property: "og:description", content: "Contact Antoine" },
              { property: "twitter:title", content: `Antoine's CV` },
              {
                property: "twitter:description",
                content: "Some of the things I've done",
              },
              { property: "twitter:image:src", content: "👋" },
            ]}
          />
          <Home>
            <Link to="/">
              <FaHome />
            </Link>
          </Home>
          <h3>Send me a message!</h3>
          <a
            href="https://t.me/iamantoine"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Telegram
            <FaTelegramPlane />
          </a>
          <div>or</div>
          <p>
          <ContactForm />
          </p>
          <hr />
          <p>
            For work stuff, head <Link to="/Cv">here</Link>
          </p>

          <Bottom>
            <p>💌 mrmagny [at] gmail ⚫ com</p>
          </Bottom>
        </div>
      </Layout>
    )
  }
}

export default Contact
