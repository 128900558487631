import posed from "react-pose"
const Parent = posed.div({
  enter: { height: "initial", opacity: 1 },
  exit: { height: 0, opacity: 0 },
})
const Input = posed.p({
  enter: { width: "initial" },
  exit: { width: 0 },
})
const Button = posed.p({
  enter: {
    opacity: 1,
    // transform: "rotate(0deg)",
  },
  exit: {
    opacity: 0,
    // transform: "rotate(-180deg)",
  },
})

export { Parent, Input, Button }